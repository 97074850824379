import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SplitSection from "../components/SplitSection"
import TextWithLogo from "../components/TextWithLogo"
import { GatsbyImage } from "gatsby-plugin-image"
import Header from "../components/header"
import gsap from "gsap"
import Footer from "../components/footer"

import Form from "../components/form"

const FormWrapper = ({ consultancyFormDescription, inputs }) => {
  return (
    <div className='flex flex-c flex-s-b h-100-p over-h'>
      <div className=''>
        <h2 className='c-2A2A2A m-w-500 d-n-b'>{consultancyFormDescription}</h2>
      </div>
      <Form memberships={false} events inputs={inputs} name='events' classEnquiryMembership='d-b-b' classEnquiry='d-n-b' linksClass='d-n-b' />
    </div>
  )
}

export default class memberships extends React.Component {
  componentDidMount() {
    let tl = gsap.timeline({ onStart: this.blackNav })
    tl.to("#nav-bar", { y: "0%", duration: 1, ease: "ease" })
  }
  blackNav() {
    const nav = document.querySelector("#nav-bar")
    nav.classList.add("black")
  }
  constructor(props) {
    super(props)
    this.state = {
      currentInput: "FULL NAME",
      inputs: [
        {
          name: "Full name",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR Full Name",
          placeholder: "YOUR NAME",
        },
        {
          name: "Company/organisation",
          required: false,
          type: "text",
          title: "PLEASE PROVIDE YOUR Company/organisation",
          placeholder: "YOUR Company/organisation",
        },
        {
          name: "Email",
          required: true,
          type: "email",
          title: "PLEASE PROVIDE YOUR Email",
          placeholder: "YOUR Email",
        },
        {
          name: "Phone",
          required: true,
          type: "number",
          title: "PLEASE PROVIDE YOUR Phone Number",
          placeholder: "YOUR Phone Number",
        },
        {
          name: "City/location",
          required: true,
          type: "text",
          title: "PLEASE PROVIDE YOUR City/location",
          placeholder: "YOUR City/location",
        },
      ],
      currentIndex: 0,
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            datoCmsConsultancy {
              consultancyHeading
              consultancyTitle
              consultancyParagraph
              consultancyImage {
                gatsbyImageData
              }
              consultancyFormTitle
              consultancyFormDescription
              useLinkInstead
              link
            }
          }
        `}
        render={({ datoCmsConsultancy }) => (
          <Layout>
            <Seo title='Events' />

            <Header className='black w-b' isLink={datoCmsConsultancy.useLinkInstead} link={datoCmsConsultancy.link}/>
            <div data-scroll-container id='scroll-container' className='data-scroll-container'>
              <div className=' back-white p-t-50'>
                <SplitSection
                  classRight='c-d-n-b '
                  wrapperClassName='stack'
                  subWrapperClassName='b-b-1-br'
                  right={
                    <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                      <GatsbyImage alt='' quality={100} image={datoCmsConsultancy.consultancyImage.gatsbyImageData} className='w-100-p h-100-p ' layout='fullWidth' placeholder='dominantColor' />
                    </div>
                  }
                >
                  <div className='flex flex-c flex-s-b h-100-p '>
                    <h1 className='al-left m-b-140 m-w-450 m-b-40  '>{datoCmsConsultancy.consultancyHeading}</h1>
                    <TextWithLogo removeLink textClassName='alt' title={datoCmsConsultancy.consultancyTitle} text={datoCmsConsultancy.consultancyParagraph} className='p-t-140 '></TextWithLogo>
                  </div>
                </SplitSection>
                <SplitSection right={<FormWrapper inputs={this.state.inputs} consultancyFormDescription={datoCmsConsultancy.consultancyFormDescription} />} wrapperClassName='b-t-1-b stack' alStart classRight='c-d-n-b ' subWrapperClassName='b-b-1-br'>
                  <div className='flex flex-c'>
                    <h1 className='c-2A2A2 al-left form-heading-0'>{datoCmsConsultancy.consultancyFormTitle}</h1>
                    <h2 className='c-2A2A2A m-w-500 d-b-b display-none m-t-120 '> {datoCmsConsultancy.consultancyFormDescription}</h2>
                  </div>
                </SplitSection>
                <Footer />
              </div>
            </div>
          </Layout>
        )}
      />
    )
  }
}
